/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import '../app/shared/_directives/mat-table-responsive/mat-table-responsive.directive';

.title {
    background-color: #25ACD9;
    border-radius: 3px;
    color: white;
}

th {
    background-color: #EEEEEE !important;
    font-size: 14px !important;
}

.mat-tooltip {
    margin: 0px !important;
}

table {
    width: 100%;
}

.no-record {
    padding: 15px;
    text-align: center;
}

.mat-step-icon {
    background-color: #25ACD9 !important;
}

mat-progress-bar .mat-progress-bar-fill::after {
    background-color: #25ACD9 !important;
}

mat-form-field {
    width: 100%;
}

.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
    transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
    opacity: 1 !important;
}

.table-container {
    max-height: 600px;
    /* Set a max height for the container to enable scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.clickable-tr {
    cursor: pointer;
}

.download-link {
  color: #337ab7; 
  text-decoration: none; 
  cursor: pointer; 
}

.download-link:hover {
  color: #23527c;
  text-decoration:underline; 
}

.sticky-input-container {
    position: sticky;
    top: 0;
    background: white; 
    z-index: 1; /* Ensure input box is above the options */
    padding: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
}